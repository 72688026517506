import {api} from 'api/api';
import {hbmWidgetUtilFunc} from 'components/pc/widgets/hmb/hmb-functions';
import {TreeCategory} from 'api/DataProvider';
import {DatabaseHierarchy, IApiServer, ITagHierarchy, ITreeNode} from 'api/data-types';
import {LocalStorageManager} from 'utils/local-storage-manager';

const convertToNode = (node: ITagHierarchy, hierarchy: string[]) => {
  const {subnode, name} = node;
  const newHierarchy = [...hierarchy, name];

  if (subnode === null || subnode === undefined) {
    if (name === 'weather') return {}; // 트리 말단 (Leaf Node 이면서 name이 'weather' 인 항목일시 빈 값 반환)
    return {
      value: JSON.stringify([...newHierarchy]),
      label: name
    };
  } else {
    return {
      value: JSON.stringify(newHierarchy),
      label: name,
      children: subnode
        .map((node) => convertToNode(node, newHierarchy))
        .filter((item) => !hbmWidgetUtilFunc.isEmptyObj(item)) // 빈 Object (weather 항목 제거하는 filter method)
    };
  }
};

const rootNode = {
  Plant: 'plant',
  Commodity: 'commodity',
  Weather: 'weather',
  Model: 'model'
};

export const getHierarchy = async (category: TreeCategory) => {
  const data = await api.post<ITagHierarchy[]>(
    '/tags/get_hierarchy',
    {category: rootNode[category]},
    {errorReturn: []}
  );
  const rootNodeHierarchy = [category];
  const nodes = data.map((item) => convertToNode(item, [...rootNodeHierarchy]));
  return {
    hierarchy: {
      value: JSON.stringify([...rootNodeHierarchy]),
      label: rootNodeHierarchy[0],
      children: nodes
    } as ITreeNode,
    json: data as ITagHierarchy[]
  };
};

const defaultServer: IApiServer = {type: 'cloud', area: process.env.REACT_APP_STATIC_CLOUD_AREA || 'us-east'};
export const getApiHost = (): string => {
  // return 'https://backend.test.seoul.server.processmetaverse.com';

  const savedServer = LocalStorageManager.getItem<IApiServer>('API_SERVER');
  const apiServer = savedServer || defaultServer;
  if (!savedServer) {
    LocalStorageManager.setItem<IApiServer>('API_SERVER', defaultServer);
  }
  if (apiServer?.type === 'cloud') {
    return 'https://' + apiServer?.area + '.server.' + process.env.REACT_APP_CLOUD_DOMAIN;
  }
  return process.env.REACT_APP_API_LOCAL_HOST;
};

export const convert2DRawArrToDbObject = (data: any[][], id: string, name: string) => {
  if (!data) return;

  let headersRowIndex = -1;
  for (let i = 0; i < data.length; i++) {
    if (data[i].some((cell) => cell !== undefined && cell !== null && cell !== '')) {
      headersRowIndex = i;
      break;
    }
  }

  if (headersRowIndex === -1) return; // No valid header row found

  const headers = data[headersRowIndex].filter((item: any) => item);
  const rows = data.slice(headersRowIndex + 1);

  if (!headers || headers.length === 0) return;

  const result: any = {};
  if (typeof headers[0] === 'string' && headers[0]?.toLowerCase().includes('time')) {
    rows.forEach((row) => {
      const timestamp = row[0];
      headers.forEach((tag, colIndex) => {
        if (colIndex === 0) return; // Skip the timestamp column
        if (!result[tag]) {
          result[tag] = [];
        }
        const value = row[colIndex] !== undefined ? row[colIndex] : null;
        result[tag].push({timestamp, value});
      });
    });
  } else {
    rows.forEach((row, rowIdx) => {
      headers.forEach((tag, colIndex) => {
        if (!result[tag]) {
          result[tag] = [];
        }
        const value = row[colIndex + 1] !== undefined ? row[colIndex + 1] : null;
        result[tag].push({timestamp: rowIdx, value});
      });
    });
  }

  const hasKeyList = Object.keys(result);
  const hierarchy: DatabaseHierarchy = {
    database: id,
    detail: 'local database',
    data: hasKeyList.map((item) => ({name: item}))
  };

  return {
    hierarchy,
    db: result,
    id,
    name
  };
};
