import {MouseEvent, PropsWithChildren, ReactElement, useEffect, useState} from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import {SizeTypes} from 'components/common/types';
import {BasicSpinner} from 'components/common/index';

const Container = styled.div`
  width: 100%;
  height: 100%;
  margin: auto;
  position: absolute;
  z-index: 10;
  display: flex;
  transition: height 0.2s 1s;
  background-color: rgba(92, 160, 238, 0.13);

  &.top-center {
    justify-content: center;
  }

  &.top-right {
    justify-content: flex-end;
  }

  &.top-left {
    justify-content: flex-start;
  }

  &.center-center {
    align-items: center;
    justify-content: center;
  }

  &.center-left {
    align-items: center;
    justify-content: flex-start;
  }

  &.center-right {
    align-items: center;
    justify-content: flex-end;
  }

  &.overlay {
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 600;
  }
`;

type StyleProps = {
  $transitionTime: number;
};
const MessageContainer = styled.div<StyleProps>`
  display: flex;
  margin: auto;
  opacity: 0;
  width: 300px;
  height: 60px;
  border-radius: 5px;
  padding: 20px;
  background-color: #292d3e;
  border: 1px solid #000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  transition: opacity ${(props) => props.$transitionTime}s;

  &.fade-in {
    opacity: 1;
    transition: all 0.2s;
  }
`;
const Inner = styled.div`
  margin: auto;
  display: flex;
  align-items: center;
`;
const SpinnerContainer = styled.div`
  width: 34px;
  height: 34px;
  margin-right: 10px;
`;
const MessageText = styled.div`
  font-size: 14px;
  line-height: 1.6em;
  white-space: pre-line;
  color: rgba(255, 255, 255, 0.8);
`;

type IProps = PropsWithChildren & {
  type?: 'default' | 'inline' | 'overlay';
  size?: SizeTypes;
  position?: 'top-center' | 'top-left' | 'top-right' | 'center-center' | 'center-left';
  isShow: boolean;
  delayTime?: number;
  onClick?(e: MouseEvent): void;
};

function MessageSpinner({
  children,
  type = 'default',
  size = 'lg',
  position = 'top-center',
  isShow,
  delayTime = 0.5, // 단위: sec
  onClick
}: IProps): ReactElement {
  const [render, setRender] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isShow) {
      setRender(true);
    } else {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        setRender(false);
      }, delayTime * 1000);
    }
    return () => clearTimeout(timer);
  }, [delayTime, isShow]);

  if (render) {
    return (
      <Container className={classNames(type, position, {show: isShow})}>
        <MessageContainer $transitionTime={delayTime} className={classNames(size, {'fade-in': isShow})}>
          <Inner>
            <SpinnerContainer onClick={onClick}>
              <BasicSpinner isShow={true} type="inline" margin={0} />
            </SpinnerContainer>
            <MessageText>{children || 'Loading data.\nPlease wait a moment.'}</MessageText>
          </Inner>
        </MessageContainer>
      </Container>
    );
  } else {
    return undefined;
  }
}

export default MessageSpinner;
